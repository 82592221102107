<template>
  <b-row class="match-height">
    <b-col class="col-12">
      <leaflet-basic v-if="show"/>
      <b-card body-class="p-0">
        <component
          :is="LMap"
          :zoom.sync="zoom"
          :options="mapOptions"
          :bounds="bounds"
          style="height: 500px; width: 100%"
          ref="map"
        >
          <component :is="LTileLayer" :url="url" />

          <component
            :is="LMarker"
            v-for="marker in markers"
            :key="marker.id"
            :visible="true"
            :draggable="false"
            :lat-lng.sync="marker.position"
          >
            <component :is="LIcon" v-if="marker.selected && marker.status === 'approved'">
              <b-img
                :src="require('@/assets/images/locations/accept.png')"
                height="25"
              />
            </component>
            <component :is="LIcon" v-if="marker.selected && marker.status === 'running'">
              <b-img
                :src="require('@/assets/images/locations/running.png')"
                height="25"
              />
            </component>
            <component :is="LPopup" :content="marker.tooltip" />
            <component :is="LTooltip" :content="marker.tooltip" />
          </component>
          <component :is="LControlZoom" :position="zoomPosition" />
        </component>
      </b-card>
    </b-col>
    <b-col cols="12">
        <h4>{{$t('spaces')}} ({{counter}})</h4>
        <b-card>
        <div class="d-flex">
          <b-input-group>
             <b-icon class="position-icon" icon="search"/>
            <b-form-input class="pl-2"
            v-model="location" :placeholder="$t('campaignsOoh.searchUbication')" @input="searchLocation()">
            </b-form-input>
            <b-input-group-append>
              <b-spinner variant="primary" class="spinner-searcher-ooh" v-if="loader_search"/>
            </b-input-group-append>
          </b-input-group>
          <b-dropdown class="ml-1" :text="$t('campaigns.actions')" variant="flat-secondary" right v-if="markers.length > 0">
            <b-dropdown-item @click="selectAll()">
              <feather-icon icon="CheckSquareIcon" size="16" class="align-middle"/>
              {{ $t('campaigns.realty.actionSelectAll') }}
            </b-dropdown-item>
            <b-dropdown-item @click="checked_locations = []">
              <feather-icon icon="XSquareIcon" size="16" class="align-middle"/>
              {{ $t('campaigns.realty.actionRemoveSelection') }}
            </b-dropdown-item>
            <b-dropdown-divider />
            <b-dropdown-item @click="togglesLocations()" :disabled="checked_locations.length === 0">
              <div v-if="pending">
                <feather-icon icon="UserPlusIcon" size="16" class="align-middle" />
                {{ $t('campaigns.approveBtn') }}
              </div>     
              <div v-else>
                <feather-icon icon="UserMinusIcon" size="16" class="align-middle" />
                {{ $t('campaigns.rejectBtn') }}
              </div>
            </b-dropdown-item>
          </b-dropdown>
        </div>
        </b-card>
        <div v-if="markers.length > 0" class="boxResults" ref="scrollable" @scroll="handleScroll()">
          <b-row class="mt-3 match-height">
            <b-col cols="12" class="col-sm-12 col-md-6 col-lg-4 col-xl-3" v-for="(marker, index) in markersFiltered" :key="index">
              <b-card class="cardResult">
                <div>
                  <div  class="w-100">
                    <div class="badges">
                      <b-badge variant="primary">
                        {{marker.category}}
                      </b-badge>
                    </div>
                    <b-form-checkbox
                          v-model="checked_locations"
                          :value="marker.id"
                          class="custom-control-primary checkBoxCard"
                        >
                    </b-form-checkbox>
                    <div @click="showModalMethod(marker.img)">
                      <img :src="getPreview(marker)" @error="errorLoad(index)" :alt="marker.tooltip" img-left class="cardImage h-100 block"/>
                    </div>
                  </div>
                  <div class="w-100 d-flex align-content-between flex-wrap p-1 cardBody">
                    <div class="block w-100 mb-1">
                      <b-card-text  @click="zoomMarker(marker)" class="textZoom font-weight-bold">
                       {{marker.tooltip}} 
                      </b-card-text>
                      <b-row>
                        <b-col v-if="marker.format">{{marker.format}}</b-col>
                        <b-col v-if="marker.state !== 'Default'"> {{marker.state}}</b-col>
                      </b-row>
                      <b-row>
                        <!-- <b-col>ID: {{marker.id}}</b-col> -->
                        <b-col> IDOH: {{marker.external_id ? marker.external_id : marker.id}}</b-col>
                      </b-row>
                    </div>
                    <div class="block w-100 border-top">
                      <div class="mt-1 d-flex justify-content-between">
                        <span class="font-weight-bold scopetext">Alcance: 1,200,000/mes</span>
                        <b-button v-if="marker.status !== 'running'" variant="primary" @click="toggleMarker(marker)" class="float-right">
                          {{getTextButton()}}
                        </b-button>
                      </div>
                    </div>
                  </div>
                </div>
              </b-card>
            </b-col>
          </b-row>
          <b-pagination
            class="d-flex justify-content-center"
            v-model="current_page"
            :total-rows="markers.length"
            :perPage="per_page"
          >
          </b-pagination>
        </div>
    </b-col>
    <b-modal
      centered
      size="lg"
      v-model="showModal"
      hide-footer
    >
      <b-card-img :src="imgModal"></b-card-img>
    </b-modal>
  </b-row>
</template>

<script>
import { BRow, BCol, BCard, BImg, BCardText, BButton, BFormInput, BBadge, BDropdown, BDropdownItem, BDropdownDivider, BCardImg, BFormCheckbox, BPagination, BSpinner, BInputGroup, BInputGroupAppend} from 'bootstrap-vue'
import LeafletBasic from '@/views/charts-and-maps/maps/leaflet/LeafletBasic.vue'

export default {
  props: {
    markers: {
      type: Array,
      default: () => [],
    },
    counter: {
      type: Number,
      default: 0
    },
    pending: {
      type: Boolean,
      default: true
    },
    loader_search: {
      type: Boolean,
      default: true
    },
    is_approved: {
      type: Boolean,
      default: false
    }
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BImg,
    BButton,
    BFormInput,
    BBadge,
    BFormCheckbox,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BCardImg,
    BPagination,
    BSpinner,
    BInputGroup,
    BInputGroupAppend,
    LeafletBasic,
  },
  data() {
    return {
      current_page: 1,
      per_page: 20,
      zoom: 8,
      show: false,
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      mapOptions: {
        scrollWheelZoom: false,
        zoomControl: false,
        attributionControl: false,
        zoomSnap: true,
        touchZoom: false,
      },
      zoomPosition: 'topleft',
      bounds: null,
      location: '',
      checked_locations: [],
      // token: 'sk.eyJ1IjoiZ2Fib2hzOTIiLCJhIjoiY2t5bHhnd2lyMHh0ZDJ2cGs3NnJsZDJ6aiJ9.9eQhWKihqNDBcYFvMhJMFg',
      imgModal: '',
      showModal: false,
      imageDefault: require('@/assets/images/dashboard/b1b1b1.png'),
      LMap: null,
      LTileLayer: null,
      LControlZoom: null,
      LMarker: null,
      LTooltip: null,
      LPopup: null,
      latLngBounds: null,
      LIcon: null,
      Icon: null,
    }
  },
  async mounted() {
    const vue2_leaflet = (await import('vue2-leaflet'));
    const leaflet = (await import('leaflet'));

    this.latLngBounds = leaflet.latLngBounds;
    this.Icon = leaflet.Icon;
    this.bounds = this.latLngBounds(
      { lat: 14.1303191397, lng: -117.0556529104 },
      { lat: 32.5803068469, lng: -86.030154997 }
    )
    delete this.Icon.Default.prototype._getIconUrl
    this.Icon.Default.mergeOptions({
      iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
      iconUrl: require('leaflet/dist/images/marker-icon.png'),
      shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
    })

    this.LMap = vue2_leaflet.LMap;
    this.LTileLayer = vue2_leaflet.LTileLayer;
    this.LControlZoom = vue2_leaflet.LControlZoom;
    this.LPopup = vue2_leaflet.LPopup;
    this.LMarker = vue2_leaflet.LMarker;
    this.LTooltip = vue2_leaflet.LTooltip;
    this.LIcon = vue2_leaflet.LIcon;
  },
  methods: {
    errorLoad(index) {
      this.markersFiltered[index].img = this.imageDefault;
    },
    getPreview(marker) {
      if (marker.img) return marker.img
      else return  this.imageDefault
    },
    selectAll () {
      this.markers.forEach(marker => {
        this.checked_locations.push(marker.id)
      });
    },
    togglesLocations() {
      if (this.checked_locations.length === 0) {
        this.$swal({
          title: this.$t('campaigns.realty.emptySelection'),
          text: this.$t('campaigns.realty.mustSelect'),
          icon: 'warning',
          showCancelButton: false,
          confirmButtonText: this.$t('campaigns.realty.accept'),
          cancelButtonText: this.$t('campaigns.realty.cancel'),
          customClass: {
            confirmButton: 'btn btn-warning',
          },
          buttonsStyling: false
        })
      } else {
        const title = this.pending ? this.$t('campaigns.realty.sureAccept') : this.$t('campaigns.realty.sureRemove')
        const text = this.pending ? this.$t('campaigns.realty.locationsWillAdd') : this.$t('campaigns.realty.locationsWillRemove')
        this.$swal({
          title,
          text,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: this.$t('campaigns.realty.accept'),
          cancelButtonText: this.$t('campaigns.realty.cancel'),
          customClass: {
            confirmButton: 'btn btn-warning',
            cancelButton: 'btn btn-outline-secondary mr-1'
          },
          reverseButtons: true,
          buttonsStyling: false
        }).then(result => {
          if (result.value) {
            this.$emit('toggleMarker', this.checked_locations, this.pending)
          }
        })
      }
    },
    searchLocation() {
      this.loader_search = true
      setTimeout(() => {
        this.$emit('searchLocation', this.location, this.pending)
      }, 250);
    },
    zoomMarker(marker) {
      this.$refs.map.mapObject.setView(marker.position, 150)
    },
    getTextButton() {
      if (this.is_approved) return this.$t('remove')
      return this.$t('add')
    },
    toggleMarker(marker) {
      const title = this.pending ? this.$t('campaigns.realty.sureAccept') : this.$t('campaigns.realty.sureRemove')

      this.$swal({
        title,
        text: marker.tooltip,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: this.$t('campaigns.realty.accept'),
        cancelButtonText: this.$t('campaigns.realty.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger mr-1'
        },
        buttonsStyling: false,
        reverseButtons: true
      }).then(result => {
        if (result.isConfirmed && result.value) {
          this.$emit('toggleMarker', [marker.id], this.pending)
        }
      })
    },

    showModalMethod(img) {
      this.imgModal = img;
      this.showModal = true;
    }
  },
  computed: {
    markersFiltered () {
      const items = this.markers
      return items.slice((this.current_page - 1) * this.per_page, this.current_page * this.per_page)
    },
  },
  watch: {
    current_page(new_value) {
      if (parseInt(this.markers.length / this.per_page) === new_value) {
        this.$emit('next_page', this.pending)
      }
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/map-leaflet.scss';
div.selected {
  background-color: red;
}
.cardImage {
  object-fit: cover;
  width: 100%;
  height: 1000px !important;
  max-height: 250px;
}
.cardImage:hover {
  cursor: pointer;
}
.cardResult:hover {
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}
.cardResult:hover .textZoom {
  color: #7367F0;
}
.boxResults {
  height: auto;
}
.textZoom:hover {
  cursor: pointer;
  text-decoration: underline;
}
.cardResult > div {
  padding: 0px;
}
.cardBody {
  height: auto;
}
.scopetext {
  margin-top: 0.6em;
}
.badges {
  position: absolute;
  top: 0.7em;
  left: 0.5em;
}
.checkBoxCard {
  position: absolute;
  top: 0.7em;
  right: 0.5em;
}
.position-icon{
  position: absolute;
  margin-left: 5px;
  margin-top: 12px;
  z-index: 10;
}
.spinner-searcher-ooh {
  margin-left: -2.5em;
  margin-top: 0.3em;
  z-index: 10;
}
</style>
